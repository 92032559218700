import anime from "animejs/lib/anime.es.js";

var lpp = { utils: {} };

lpp.initPage = () => {
  lpp.anim = {
    vespa: anime({
      targets: ".vespa",
      translateX: [0, window.innerWidth],
    }),
  };
};

lpp.utils = {
  isElementInViewport: function (el) {
    var rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight ||
          document.documentElement.clientHeight) /* or $(window).height() */ &&
      rect.right <=
        (window.innerWidth ||
          document.documentElement.clientWidth) /* or $(window).width() */
    );
  },
};

window.onscroll = () => {
  let delivery = document.getElementById("delivery");

  if (lpp.utils.isElementInViewport(delivery)) {
    lpp.anim.vespa.seek(window.scrollY / 10);
  }
};

document.addEventListener("DOMContentLoaded", () => {
  lpp.initPage();

  (document.querySelectorAll(".notification .delete") || []).forEach(
    ($delete) => {
      const $notification = $delete.parentNode;

      $delete.addEventListener("click", () => {
        $notification.parentNode.removeChild($notification);
      });
    }
  );
});
